// vendors
@import './vendors/_tailwind';

// base
@import './base/_reset';

// fonts
@import './font';

.product-cart-item-collapse {
  .ant-collapse-header {
    padding-left: 0 !important;
  }
  .ant-collapse-expand-icon {
    padding-inline-end: 4px !important;
  }
  .ant-collapse-content-box {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.primary-header {
  &.ant-popover {
    padding-top: 0 !important;
  }

  .ant-popover-inner {
    padding: 0 !important;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow: hidden;
  }
}

.primary-header-menu-drawer {
  .ant-drawer-header {
    background-color: #038f63;
    padding: 16px 8px;
  }

  .ant-drawer-close {
    margin-right: 0;
    padding: 0;
  }

  .ant-collapse-header {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-weight: 500;
  }
}

.primary-popover-menu-item {
  position: relative;

  &.active {
    &:not(:first-child)::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
      height: 2px;
      display: block;
      background-color: #bbf7d0;
    }

    &:first-child::before {
      height: 0px;
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 2px;
      display: block;
      background-color: #bbf7d0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 2px;
    display: block;
    background-color: #bbf7d0;
  }
}

#input-code {
  input {
    text-align: center;
  }
}

.tlt,
.two-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 20px;
  max-height: 44px;
}

.three-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 20px;
  max-height: 68px;
}

.three-line-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 20px;
  max-height: 60px;
}

.zalo-chat-widget {
  right: 75px !important;
  bottom: 155px !important;
}

.app__zalo-chat-widget-container {
  .zalo-chat-widget {
    bottom: 128px !important;
    right: 128px !important;
  }

  .zalo-chat-widget-time {
    position: fixed;
    bottom: 90px;
    right: 112px;

    color: #2962ff;
    background: #fff;
    border-radius: 10px;
    padding: 3px 10px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
    margin: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .app__zalo-chat-widget-container {
    .zalo-chat-widget {
      bottom: 102px !important;
      right: 26px !important;
    }

    .zalo-chat-widget-time {
      bottom: 76px;
      right: 12px;
    }
  }
}

@media only screen and (max-width: 512px) {
  .app__zalo-chat-widget-container {
    .zalo-chat-widget {
      bottom: 30px !important;
      right: 30px !important;
    }

    .zalo-chat-widget-time {
      bottom: 5px;
      right: 12px;
    }
  }
}

div.slick-slide[aria-hidden='true'] a[href],
div.slick-slide[aria-hidden='true'] area[href],
div.slick-slide[aria-hidden='true'] input:not([disabled]),
div.slick-slide[aria-hidden='true'] select:not([disabled]),
div.slick-slide[aria-hidden='true'] textarea:not([disabled]),
div.slick-slide[aria-hidden='true'] button:not([disabled]),
div.slick-slide[aria-hidden='true'] [tabindex]:not([disabled]),
div.slick-slide[aria-hidden='true'] [contenteditable='true']:not([disabled]) {
  visibility: hidden;
}
